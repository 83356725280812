import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["state", "county", "zip"]

  connect() {
    this.regionTypeChange(); // call this method on page load to set the initial state
  }

  stateChange() {
    let selectedStates = Array
        .from(this.stateTarget.querySelectorAll('input[type="checkbox"]:checked'))
        .map(checkbox => checkbox.value);

    this.populateCounties(selectedStates);
    this.countyTarget.parentElement.style.display = selectedStates.length > 0 ? "block" : "none";
  }

  populateCounties(selectedStates) {
    // clear current options
    this.countyTarget.innerHTML = '';

    selectedStates.forEach(state => {
      fetch(`/get_counties?state=${state}`)
          .then(response => {
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            return response.json();
          })
          .then(counties => {
            counties.forEach(county => {
              let option = document.createElement("option");
              option.text = county;
              option.value = county;
              this.countyTarget.add(option);
            });
          })
          .catch(() => console.log('There was an error fetching counties for the state:' + state));
    });
  }
}
